import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Table } from 'react-bootstrap';

const CSGuideGremPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gremory's Bar</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/category_grem.png"
            alt="Gremory's Bar"
          />
        </div>
        <div className="page-details">
          <h1>Gremory's Bar</h1>
          <h2>A guide for the Gremory's Bar event.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <StaticImage
          src="../../../images/counterside/generic/guide_grem_2.jpg"
          alt="Grem's Bar"
        />
        <p>
          Gremory’s Bar is an periodically run event where you’ll make some
          cocktails.
        </p>
        <p>
          ...and spend Eternium. A LOT. Of Eternium. Like, seriously A LOOOOOOT
          of Eternium. To be exact, the milestones stop at 650,000 Eternium. And
          you’ll need to spend that in a span of two weeks, alongside a set of
          daily missions, but those are simple anyway. Now before you pull your
          hair out on that Eternium number, do know that you don’t need to go
          through all the Eternium milestones to get the grand prize of 100 Set
          Binaries.
        </p>
        <SectionHeader title="Candies System" />
        <p>
          This event uses a system where you do missions to get candies, which
          do two things:
        </p>
        <ul>
          <li>Traditional event shop,</li>
          <li>Milestones.</li>
        </ul>
        <p>
          The milestones is what this guide will focus on, as the event shop is
          easily clearable anyway.
        </p>
        <p>
          You gain rewards at certain amounts of candies earned total,
          regardless if you’ve spent them already or not. Most of these
          milestones are ingredients for the cocktails, which will be covered in
          another section, but the final milestone at 400 candies is 100 Set
          Binaries, which is what many people will be eyeing.
        </p>
        <p>
          By doing the event dailies, you can get 5 candies daily, and 5 more if
          you spend 300 quartz on anything. Assuming you’re active daily, you
          get 70 candies not counting the quartz missions. Since the event spans
          two weeks, you should buy the weekly pack for Classified tickets,
          already filling two of these missions - however, on the week the event
          starts, if you waited to buy them, you can get a third pinned down.
          This also applies to monthly Set Binaries, if you’re willing to wait.
        </p>
        <p>
          The other missions last the whole two weeks, and they’re simply use
          Eternium:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Milestone No.</th>
              <th>Candies Awarded</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1-15</td>
              <td>3</td>
              <td>45</td>
            </tr>
            <tr>
              <td>16-25</td>
              <td>4</td>
              <td>40</td>
            </tr>
            <tr>
              <td>26-35</td>
              <td>5</td>
              <td>50</td>
            </tr>
            <tr>
              <td>36-39</td>
              <td>6</td>
              <td>24</td>
            </tr>
            <tr>
              <td>40-45</td>
              <td>7</td>
              <td>42</td>
            </tr>
            <tr>
              <td>46-52</td>
              <td>8</td>
              <td>56</td>
            </tr>
            <tr>
              <td>53-59</td>
              <td>9</td>
              <td>63</td>
            </tr>
            <tr>
              <td>60</td>
              <td>15</td>
              <td>15</td>
            </tr>
          </tbody>
        </Table>
        <p>
          In total, these milestones give 335 candies. However, the final
          milestone requires 650,000 Eternium, which most players will not
          reach. It is possible though even without buying any Eternium, so
          start hoarding your Eternium: leave the mailbox, dives, Counter Pass,
          etc. unclaimed unless they’re about to expire, and get ready to farm
          dispatches and dives.
        </p>
        <p>
          Discounting the 70 candies you get from dailies, and 10 more from at
          least 2 Classified quartz packs, you only need to reach milestone 59 -
          but this is still 645,000 Eternium. If you spent 300 quartz daily on
          the other hand, you will only need to reach milestone 50, which is
          510,000 Eternium.
        </p>
        <p>
          In short, there are two choices to get those sweet 100 glimmering gold
          Binaries:
        </p>
        <ul>
          <li>
            Hoard Eternium, be very active in farming 1hr dispatch and dives
            during the event, or
          </li>
          <li>Bust 300 quartz daily.</li>
        </ul>
        <p>
          Of course you can take a blend of both. And a big question is, what is
          the best way to spend 300 quartz daily? The simple answer is Eternium
          or shop refresh. Eternium obviously helps the milestones as well.
        </p>
        <p>
          The most important takeaway here is, do you think the Set Binaries are
          worth the quartz you’re potentially using on them. Think that through
          carefully and use your own judgement. I will mention though that 100
          Set Binaries for 3300 quartz is the same efficiency as the 30 Set
          Binary quartz pack monthly.
        </p>
        <p>
          Do note because of how much you need to farm for this event, it’s
          likely that if you miss a single day, it’s over for you. It is
          possible, but likely costly, to make a comeback, but as mentioned
          earlier, judge if you think it’s worth it or not.
        </p>
        <p>
          Also if you want to do dispatch farming, check out the dispatch guide
          to optimize for dive farming.
        </p>
        <SectionHeader title="Cocktails" />
        <p>
          To make a cocktail, you choose 2 of 3 ingredients and a mixing method.
          Then you either use it as a loot bag or give it to Gremory, which you
          should do daily - she tells you which drink she wants when you tap
          her. Thankfully someone already made an image about each cocktail you
          can mix. Thanks Juan Rafael Escolar from the CS SEA Facebook group.
        </p>
        <StaticImage
          src="../../../images/counterside/generic/guide_grem_3.webp"
          alt="Grem's Bar"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default CSGuideGremPage;

export const Head: React.FC = () => (
  <Seo
    title="Gremory's Bar | Counter Side | Prydwen Institute"
    description="A guide for the Gremory's Bar event."
  />
);
